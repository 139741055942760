import { PM_TYPE } from '@/constants'

export const formatProductModel = ({ productModelType, businessName, userExportsName }) => {
  switch (productModelType) {
    case PM_TYPE.USER_EXPORTS:
      return (
          <div class="table-cell-pm">
              <span class="pills__ue">
                <mi-tooltip>User Export</mi-tooltip>
              </span>
              <span class="table-cell__text">{userExportsName}</span>
          </div>
      )
    case PM_TYPE.PRODUCT_MODEL:
      return (
          <div class="table-cell-pm">
              <span class="pills__pm">
                <mi-tooltip>Product Model</mi-tooltip>
              </span>
              <span class="table-cell__text">{businessName}</span>
          </div>
      )
    default:
      return '-'
  }
}
